.ourStoryContainer {
  flex: 1 1 auto;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  padding-bottom: var(--spacing-md);

  .column1 {
    flex: 1 1 400px;
    width: 500px;
    padding-right: var(--spacing-xxl);
    @media (max-width: 992px) {
      padding-right: 0px;
    }

    .text {
      p {
        font-size: var(--font-size-md-sm);
        line-height: 175%;
      }
    }

    .readMoreLink {
      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      display: flex;
      align-items: center;
      text-align: right;
      color: #666;

      .arrow {
        margin-left: var(--spacing-sm);
      }
    }
  }
  .column2 {
    position: relative;
    display: flex;
    flex-direction: column;
    width: max-content;
    margin: 0 auto var(--spacing-md) auto;

    @media (max-width: 992px) {
      width: 100%;
    }

    .slider {
      width: 521px;
      margin: auto;

      @media (max-width: 768px) {
        width: 385px;
      }
      @media (max-width: 480px) {
        width: 100%;
      }
    }
  }
  .title {
    font-size: var(--font-size-xxl);
    margin-bottom: 65px;
    @media (max-width: 992px) {
      font-size: var(--font-size-lg);
    }

    &.desktopTitle {
      @media (max-width: 992px) {
        display: none;
      }
    }
  }
}
