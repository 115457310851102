.row {
  display: flex;
  flex-wrap: wrap;

  article:global(:last-of-type) {
    @media (width > 992px) and (width < 1200px) {
      flex-grow: 0.1;
    }
    @media (max-width: 992px) {
      flex-grow: 1;
    }
  }
}
