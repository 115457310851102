.titleContainer {
  &.light {
    color: var(--light-text);
  }
  .title {
    display: flex;
    flex-wrap: wrap;
    max-width: 97vw;
    // margin-bottom: var(--spacing-sm);
    margin-left: 0px;
    line-height: auto;

    span {
      line-height: 1;
    }

    .firstWord {
      // margin-right: 0.3em;
      padding-bottom: 0.3em;
    }
    .highlight {
      display: inline;
      font-weight: 700;
    }
  }
  .subtitle {
    display: block;
    font-size: 24px;
    line-height: 132%;
    color: #000;
    font-weight: 300;
  }
}
