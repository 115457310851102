.iconBox {
  padding: var(--spacing-xs);
  margin-bottom: var(--spacing-sm);
  &.lightText {
    color: var(--dark-text);
  }
  &.lightBackground {
    background: var(--light-background);
  }
  &.darkText {
    color: var(--light-text);
  }
  &.darkBackground {
    background: var(--dark-background);
  }
  &.transparentBackground {
    background: transparent;
  }
  .label {
    display: flex;
    align-items: center;
    margin-bottom: var(--spacing-sm);

    .icon {
      display: flex;
      align-items: center;

      margin-right: var(--spacing-sm);
    }
    .title {
      font-size: var(--font-size-md);
    }
  }
  .content {
    text-align: left;

    .text {
      margin-bottom: var(--spacing-xs);
    }
  }

  .divider {
    display: block;
    height: 1px;
    width: 50%;
    margin: var(--spacing-sm) auto 0 auto;
  }
}
