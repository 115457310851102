.container {
  .columns {
    display: grid;
    grid-template-columns: 5fr 4fr;
    grid-template-areas: "column1 column2";
    padding-top: var(--spacing-sm);

    @media (max-width: 992px) {
      grid-template-columns: 1fr;
      grid-template-areas: "column2" "column1";
    }

    .column {
      display: flex;
      margin-bottom: var(--spacing-lg);

      &.column1 {
        grid-area: column1;
        padding-right: var(--spacing-md);

        @media (max-width: 992px) {
          padding-right: 0;
        }
      }
      &.column2 {
        grid-area: column2;
        position: relative;
      }
    }

    &.column1Right {
      grid-template-columns: 4fr 5fr;
      grid-template-areas: "column2 column1";

      @media (max-width: 992px) {
        grid-template-columns: 1fr;
        grid-template-areas: "column2" "column1";
      }

      .column1 {
        padding-right: 0;
        padding-left: var(--spacing-md);

        @media (max-width: 992px) {
          padding-left: 0;
        }
      }
    }
  }
}
