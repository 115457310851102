.pageIntroContainer {
  display: grid;
  grid-template-areas:
    "title actions"
    "description description";
  grid-template-columns: 4fr 1fr;
  grid-template-rows: auto auto;
  padding-top: var(--spacing-md);
  align-items: start;
  grid-gap: 0;
  max-width: 100vw;
  padding-bottom: var(--spacing-md);

  @media (max-width: 768px) {
    grid-template-areas:
      "title"
      "actions"
      "description";
    grid-template-columns: 100%;
    grid-template-rows: repeat(3, auto);
    padding-bottom: var(--spacing-md);
  }

  .title {
    grid-area: title;
    display: block;
    width: 100%;
    padding-bottom: var(--spacing-md);
    font-family: var(--heading-font);
    font-size: var(--font-size-xxl-2);
    font-weight: 600;

    @media (max-width: 768px) {
      margin-bottom: var(--spacing-md);
      // font-size: 40px;
      font-size: clamp(32px, 10vw, 40px);

      &:has(+ .actions) {
        margin-bottom: 0;
      }
    }
  }

  .actions {
    grid-area: actions;
  }

  .description {
    grid-area: description;
    align-self: start;
    height: 100%;
    padding-right: 80px;
    @media (max-width: 768px) {
      padding-right: 0;
    }
  }
  .pageIntroduction {
    display: block;
    max-width: 768px;
  }

  .imageContainer {
    position: relative;
    grid-area: image;
    padding-top: 5px;

    .image {
      position: relative;
      min-width: 200px;
      width: 400px;
      min-height: 200px;
      height: 400px;
      margin: auto;
      border-radius: var(--border-radius-lg);
      // border-radius: 50%;
      overflow: hidden;

      @media (max-width: 992px) {
        width: 320px;
        height: 320px;
        margin-bottom: var(--spacing-md);
      }
      @media (max-width: 480px) {
        width: 300px;
        height: 300px;
        margin-bottom: var(--spacing-md);
      }
    }
  }

  &.imageRight {
    display: grid;
    grid-template-areas:
      "title title title"
      "description description image";
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: 768px) {
      grid-template-areas:
        "title"
        "image"
        "description";
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, auto);
    }

    .image {
      padding-bottom: var(--spacing-lg);
    }
  }
}
